import PropTypes from 'prop-types'
import { useTranslation } from '~/common/hooks/useTranslation'
import { Button, Col, Image, Modal, Row } from 'antd'
import React, { useState } from 'react'

function SponsorTournamentDetail(props) {
  const { t } = useTranslation()
  const { id, isShowModal, onClose, refreshData, sponsor } = props

  const [listDetail, setListDetail] = useState('detail')
  const handleClose = () => {
    onClose()
  }
  const terrain = [
    'Wilderness',
    'Water',
    'Vocalnose',
    'Plains',
    'Mountain',
    'Forest',
    'Desert',
  ]
  const mainSeed = [
    'Ame-chan',
    'AstarCats',
    'CCT',
    'DEKAUSA',
    'Faireaf',
    'Golem',
    'Hiyori',
    'Icelime',
    'KidsHero',
    'KidsVillain',
    'Layla',
    'ODENPETS',
    'Ossan',
    'P1',
    'PlanetMan',
    'SignWitch',
    'Tofu',
    'Unicorn',
    'UPA',
    'Valius',
    'Yamato',
  ]
  const listResult = [
    {
      img: 'test',
      rank: 1,
      name: 'Lia',
      score: 6,
    },
    {
      img: 'test',
      rank: 2,
      name: 'Medi',
      score: 5,
    },
    {
      img: 'test',
      rank: 3,
      name: 'HiAc',
      score: 4,
    },
    {
      img: 'test',
      rank: 3,
      name: 'Heacahe',
      score: 4,
    },
    {
      img: 'test',
      rank: 4,
      name: 'Heacahe',
      score: 3,
    },
    {
      img: 'test',
      rank: 5,
      name: 'Heacahe',
      score: 2,
    },
    {
      img: 'test',
      rank: 6,
      name: 'Heacahe',
      score: 1,
    },
  ]
  return (
    <Modal
      title={'Sponsored Tournament Detail'}
      onCancel={handleClose}
      open={isShowModal}
      width={1000}
      centered
      destroyOnClose
      footer={null}
    >
      <div>
        <Row>
          <Col span={9}>
            <div>
              <Image
                className="imageTournament"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                src={`${process.env.PUBLIC_URL}/imgs/tournament/${sponsor?.tournamentImage}.png`}
              />
            </div>
            <div className="text-[24px] mb-2 ml-3 ">
              {sponsor?.tournamentName}
            </div>
            <div
              style={{
                border: '1px solid #fff',
                padding: '8px',
                borderRadius: '8px',
              }}
            >
              <Row>
                <Col span={10} className="text-[12px]">
                  Date:
                </Col>
                <Col span={14} className="text-[12px]">
                  Feb. 20, 2024 12:00 UTC
                </Col>
              </Row>
              <Row>
                <Col span={10} className="text-[12px]">
                  Registration:
                </Col>
                <Col span={14} className="text-[12px]">
                  Feb. 19, 2023 11:59 UTC
                </Col>
              </Row>
              <Row>
                <Col span={10} className="text-[12px]">
                  Entry:
                </Col>
                <Col span={14} className="text-[12px]">
                  Feb. 20, 2023 11:30 ~ 11:49 UTC
                </Col>
              </Row>
              <Row>
                <Col span={10} className="text-[12px]">
                  Jump-in:
                </Col>
                <Col span={14} className="text-[12px]">
                  Feb. 20, 2023 11:30 ~ 11:03 UTC
                </Col>
              </Row>
            </div>
            <div className="mt-2" style={{ fontFamily: 'Rexliafree' }}>
              <div className="flex items-center">
                <div>Sponsor: Sponsor Name</div>
                <div className="ml-[10%]">ID: xxxxx</div>
              </div>
              <div>Type: Elimination</div>
              <div># of participants: 8</div>
              <div># of registrations: 19</div>
              <div>AL: No</div>
              <div>Jump-in: OK</div>
              <div className="flex items-center">
                Prize:
                <div className="flex items-center ml-1">
                  #1
                  <img
                    className="w-[25px] h-[25px]"
                    src={`${process.env.PUBLIC_URL}/svgs/guild/oas.svg`}
                    alt=""
                  />
                  100,
                </div>
                <div className="flex items-center ml-1">
                  #2
                  <img
                    className="w-[35px] h-[35px]"
                    src={`${process.env.PUBLIC_URL}/svgs/guild/oas.svg`}
                    alt=""
                  />
                  50
                </div>
              </div>
            </div>
          </Col>
          <Col span={1}>
            <div
              style={{
                borderLeft: '1px solid #fff',
                height: '100%',
                marginLeft: '50%',
              }}
            ></div>
          </Col>
          {listDetail === 'detail' && (
            <Col span={14}>
              <div>
                <Row gutter={18}>
                  <Col span={12}>
                    <div
                      style={{
                        border: '1px solid rgba(0, 0, 0, 0.5)',
                        padding: '8px',
                        borderRadius: '8px',
                        fontFamily: 'Rexliafree',
                        background: 'rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      <Row>
                        <Col span={24} className="text-[16px]">
                          Rules
                        </Col>
                      </Row>
                      <Row>
                        <Col span={14}>Rank</Col>
                        <Col span={10}>S</Col>
                      </Row>
                      <Row>
                        <Col span={14}>Monster</Col>
                        <Col span={10}>3</Col>
                      </Row>
                      <Row>
                        <Col span={14}>Turns</Col>
                        <Col span={10}>20</Col>
                      </Row>
                      <Row>
                        <Col span={14}>Special Hex</Col>
                        <Col span={10}>Random</Col>
                      </Row>
                      <Row>
                        <Col span={14}>Memory</Col>
                        <Col span={10}>Allowed</Col>
                      </Row>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div
                      style={{
                        border: '1px solid rgba(0, 0, 0, 0.5)',
                        padding: '8px',
                        borderRadius: '8px',
                        fontFamily: 'Rexliafree',
                        background: 'rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      <Row>
                        <Col span={24} className="text-[16px]">
                          Player Requirements
                        </Col>
                      </Row>
                      <Row>
                        <Col span={14}>SMS Verification</Col>
                        <Col span={10}>Required</Col>
                      </Row>
                      <Row>
                        <Col span={14}>Player Rank</Col>
                        <Col span={10}>Beginer</Col>
                      </Row>
                      <Row>
                        <Col span={14}>Cycle Score</Col>
                        <Col span={10}>600~</Col>
                      </Row>
                      <Row>
                        <Col span={14}>Battles</Col>
                        <Col span={10}>20~</Col>
                      </Row>
                      <Row>
                        <Col span={14}>Playing Days</Col>
                        <Col span={10}>30~</Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row gutter={18} className="mt-4">
                  <Col span={24}>
                    <div
                      style={{
                        border: '1px solid rgba(0, 0, 0, 0.5)',
                        padding: '8px',
                        borderRadius: '8px',
                        fontFamily: 'Rexliafree',
                        background: 'rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      <div className="text-[16px]">Monster Requirements</div>
                      <Row>
                        <Col span={6}>Cycle</Col>
                        <Col span={18}>All</Col>
                      </Row>
                      <Row>
                        <Col span={6}>Type</Col>
                        <Col span={18}>All</Col>
                      </Row>
                      <Row>
                        <Col span={6}>Age</Col>
                        <Col span={18}>~100</Col>
                      </Row>
                      <Row>
                        <Col span={6}>Terrain</Col>
                        <Col span={18} className="flex items-center">
                          {terrain.map((item, index) => (
                            <img
                              key={index}
                              className="w-[20px] h-[20px] mr-1"
                              src={`${process.env.PUBLIC_URL}/svgs/tournament/terrain/${item}.svg`}
                              alt=""
                            />
                          ))}
                        </Col>
                      </Row>
                      <div>Main Seed</div>
                      <div className="flex flex-wrap items-center">
                        {mainSeed.map((item, index) => (
                          <img
                            key={index}
                            className="w-[40px] h-[40px] mt-1"
                            src={`${process.env.PUBLIC_URL}/svgs/tournament/mainseed/${item}.svg`}
                            alt=""
                          />
                        ))}
                      </div>
                      <div>Sub Seed</div>
                      <div className="flex flex-wrap items-center">
                        {mainSeed.map((item, index) => (
                          <img
                            key={index}
                            className="w-[40px] h-[40px] mt-1"
                            src={`${process.env.PUBLIC_URL}/svgs/tournament/mainseed/${item}.svg`}
                            alt=""
                          />
                        ))}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          )}
          {listDetail === 'listResult' && (
            <Col span={14} style={{ fontFamily: 'Rexliafree' }}>
              <div className="flex items-center justify-center text-[24px]">
                {sponsor.status === 'Open'
                  ? 'Waiting to Entry (15/16)'
                  : 'Congratulations!!'}
              </div>
              {sponsor.status === 'Done' &&
                listResult.map((result, index) => (
                  <div
                    key={index}
                    className={index === 0 ? 'mt-4' : ''}
                    style={{
                      position: 'relative',
                    }}
                  >
                    <img
                      className="absolute left-[45px] top-[2px] w-[45px] h-[45px]"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/level/${result.rank}.svg`}
                      alt=""
                      style={{ zIndex: 1 }}
                    />
                    <div className="flex justify-center text-[20px] h-[50px]">
                      <div
                        className="flex items-center w-[350px] h-full justify-start p-4"
                        style={
                          index !== 0
                            ? {
                                background: 'rgba(24, 45, 86, 1)',
                                color: 'rgba(255, 255, 255, 1)',
                                borderRight: '2px solid rgba(136, 136, 136, 1)',
                                borderLeft: '2px solid rgba(136, 136, 136, 1)',
                                borderBottom:
                                  '2px solid rgba(136, 136, 136, 1)',
                              }
                            : {
                                background: 'rgba(24, 45, 86, 1)',
                                color: 'rgba(255, 255, 255, 1)',
                                border: '2px solid rgba(136, 136, 136, 1)',
                              }
                        }
                      >
                        <img
                          className="w-[35px] h-[35px] mr-4 rounded-full"
                          src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon.svg`}
                          alt=""
                        />
                        <div
                          className="mr-4"
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {result.name}
                        </div>
                      </div>

                      <div
                        className="flex items-center justify-center"
                        style={
                          index !== 0
                            ? {
                                borderRight: '2px solid rgba(136, 136, 136, 1)',
                                borderBottom:
                                  '2px solid rgba(136, 136, 136, 1)',
                                background: 'rgba(255, 255, 255, 1)',
                                color: 'rgba(0, 0, 0, 1)',
                                width: '50px',
                                height: '50px',
                              }
                            : {
                                borderRight: '2px solid rgba(136, 136, 136, 1)',
                                borderTop: '2px solid rgba(136, 136, 136, 1)',
                                borderBottom:
                                  '2px solid rgba(136, 136, 136, 1)',
                                background: 'rgba(255, 255, 255, 1)',
                                color: 'rgba(0, 0, 0, 1)',
                                width: '50px',
                                height: '50px',
                              }
                        }
                      >
                        {result.score}
                      </div>
                    </div>
                  </div>
                ))}
              {sponsor.status === 'In progress' &&
                listResult.map((result, index) => (
                  <div
                    key={index}
                    className={index === 0 ? 'mt-4' : ''}
                    style={{
                      position: 'relative',
                    }}
                  >
                    <div className="flex justify-center text-[20px] h-[50px]">
                      <div
                        className="flex items-center w-[350px] h-full justify-start p-4"
                        style={
                          index !== 0
                            ? {
                                background: 'rgba(24, 45, 86, 1)',
                                color: 'rgba(255, 255, 255, 1)',
                                borderRight: '2px solid rgba(136, 136, 136, 1)',
                                borderLeft: '2px solid rgba(136, 136, 136, 1)',
                                borderBottom:
                                  '2px solid rgba(136, 136, 136, 1)',
                              }
                            : {
                                background: 'rgba(24, 45, 86, 1)',
                                color: 'rgba(255, 255, 255, 1)',
                                border: '2px solid rgba(136, 136, 136, 1)',
                              }
                        }
                      >
                        <img
                          className="w-[35px] h-[35px] mr-4 rounded-full"
                          src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon.svg`}
                          alt=""
                        />
                        <div
                          className="mr-4"
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {result.name}
                        </div>
                      </div>

                      <div
                        className="flex items-center justify-center"
                        style={
                          index !== 0
                            ? {
                                borderRight: '2px solid rgba(136, 136, 136, 1)',
                                borderBottom:
                                  '2px solid rgba(136, 136, 136, 1)',
                                background: 'rgba(255, 255, 255, 1)',
                                color: 'rgba(0, 0, 0, 1)',
                                width: '50px',
                                height: '50px',
                              }
                            : {
                                borderRight: '2px solid rgba(136, 136, 136, 1)',
                                borderTop: '2px solid rgba(136, 136, 136, 1)',
                                borderBottom:
                                  '2px solid rgba(136, 136, 136, 1)',
                                background: 'rgba(255, 255, 255, 1)',
                                color: 'rgba(0, 0, 0, 1)',
                                width: '50px',
                                height: '50px',
                              }
                        }
                      >
                        {result.score}
                      </div>
                    </div>
                  </div>
                ))}
              {sponsor.status === 'Open' &&
                listResult.map((result, index) => (
                  <div
                    key={index}
                    className={index === 0 ? 'mt-4' : ''}
                    style={{
                      position: 'relative',
                    }}
                  >
                    <div className="flex justify-center text-[20px] h-[50px]">
                      <div
                        className="flex items-center w-[350px] h-full justify-start p-4"
                        style={
                          index !== 0
                            ? {
                                background: 'rgba(24, 45, 86, 1)',
                                color: 'rgba(255, 255, 255, 1)',
                                borderRight: '2px solid rgba(136, 136, 136, 1)',
                                borderLeft: '2px solid rgba(136, 136, 136, 1)',
                                borderBottom:
                                  '2px solid rgba(136, 136, 136, 1)',
                              }
                            : {
                                background: 'rgba(24, 45, 86, 1)',
                                color: 'rgba(255, 255, 255, 1)',
                                border: '2px solid rgba(136, 136, 136, 1)',
                              }
                        }
                      >
                        <img
                          className="w-[35px] h-[35px] mr-4 rounded-full"
                          src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon.svg`}
                          alt=""
                        />
                        <div
                          className="mr-4"
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {result.name}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {sponsor.status === 'Within 3 hours' &&
                listResult.map((result, index) => (
                  <div
                    key={index}
                    className={index === 0 ? 'mt-4' : ''}
                    style={{
                      position: 'relative',
                    }}
                  >
                    <img
                      className="absolute left-[45px] top-[2px] w-[45px] h-[45px]"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/level/${result.rank}.svg`}
                      alt=""
                      style={{ zIndex: 1 }}
                    />
                    <div className="flex justify-center text-[20px] h-[50px]">
                      <div
                        className="flex items-center w-[350px] h-full justify-start p-4"
                        style={
                          index !== 0
                            ? {
                                background: 'rgba(24, 45, 86, 1)',
                                color: 'rgba(255, 255, 255, 1)',
                                borderRight: '2px solid rgba(136, 136, 136, 1)',
                                borderLeft: '2px solid rgba(136, 136, 136, 1)',
                                borderBottom:
                                  '2px solid rgba(136, 136, 136, 1)',
                              }
                            : {
                                background: 'rgba(24, 45, 86, 1)',
                                color: 'rgba(255, 255, 255, 1)',
                                border: '2px solid rgba(136, 136, 136, 1)',
                              }
                        }
                      >
                        <img
                          className="w-[35px] h-[35px] mr-4 rounded-full"
                          src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon.svg`}
                          alt=""
                        />
                        <div
                          className="mr-4"
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {result.name}
                        </div>
                      </div>

                      <div
                        className="flex items-center justify-center"
                        style={
                          index !== 0
                            ? {
                                borderRight: '2px solid rgba(136, 136, 136, 1)',
                                borderBottom:
                                  '2px solid rgba(136, 136, 136, 1)',
                                background: 'rgba(255, 255, 255, 1)',
                                color: 'rgba(0, 0, 0, 1)',
                                width: '50px',
                                height: '50px',
                              }
                            : {
                                borderRight: '2px solid rgba(136, 136, 136, 1)',
                                borderTop: '2px solid rgba(136, 136, 136, 1)',
                                borderBottom:
                                  '2px solid rgba(136, 136, 136, 1)',
                                background: 'rgba(255, 255, 255, 1)',
                                color: 'rgba(0, 0, 0, 1)',
                                width: '50px',
                                height: '50px',
                              }
                        }
                      >
                        {result.score}
                      </div>
                    </div>
                  </div>
                ))}
            </Col>
          )}
        </Row>
        <div>
          {listDetail === 'detail' &&
            (sponsor?.status === 'Within 3 hours' ? (
              <Button
                style={{
                  width: '20%',
                  marginTop: '20px',
                  marginLeft: '61%',
                  background: 'rgba(74, 175, 79, 1)',
                  border: '2px solid rgba(74, 175, 79, 1)',
                  pointerEvents: 'none',
                }}
              >
                {sponsor.status}
              </Button>
            ) : (
              <Button
                style={{
                  width: '20%',
                  marginTop: '20px',
                  marginLeft: '61%',
                  background: 'rgba(74, 175, 79, 1)',
                  border: '2px solid rgba(74, 175, 79, 1)',
                }}
                onClick={() => setListDetail('listResult')}
              >
                Watch
              </Button>
            ))}
          {listDetail === 'listResult' && (
            <Button
              style={{
                width: '20%',
                marginTop: '20px',
                marginLeft: '61%',
                background: 'rgba(255, 0, 0, 1)',
                border: '2px solid rgba(255, 0, 0, 1)',
              }}
              onClick={() => setListDetail('detail')}
            >
              Exit
            </Button>
          )}
        </div>
      </div>
    </Modal>
  )
}

SponsorTournamentDetail.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default SponsorTournamentDetail
