export const fakeDataSector = {
  message: 'Get users successfully!',
  total: 3,
  currentPage: 1,
  hasPreviousPage: false,
  hasNextPage: false,
  pageSize: 10,
  data: [
    {
      _id: '66ce8e0893924b79a751d3f8',
      sectorName: 'Sector 1',
      totalUser: 100,
      createAt: '1726628488',
      user: [
        {
          _id: '123',
          user_id: '11',
          user_name: '0xDE108F603A85F24BaaF75Bb3b870a131b10b590A',
          address_wallet: '0xDE108F603A85F24BaaF75Bb3b870a131b10b590A',
          crt_dt: '1726628488',
          rank: 'Beginner',
          bit: 5061,
          ma_point: 50001,
          ranking: 'SSS',
        },
        {
          _id: '12223',
          user_id: '1',
          user_name: 'Luan',
          address_wallet: '0xDE108F603A85F24BaaF75Bb3b870a131b10b590A95',
          crt_dt: '1726628488',
          rank: 'Beginner',
          bit: 5061,
          ma_point: 50001,
          ranking: 'SSS',
        },
      ],
    },
    {
      _id: '66ce8e0893924b79a751d3f8123',
      sectorName: 'Sector 2',
      totalUser: 100,
      createAt: '1726628488',
      user: [
        {
          _id: '123',
          user_id: '11',
          user_name: '62222ad5a6sd4asd4a65d4a65d46a5d4ad4a6d',
          address_wallet: '0xDE108F603A85F24BaaF75Bb3b870a131b10b590A',
          crt_dt: '1726628488',
          rank: 'Beginner',
          bit: 5061,
          ma_point: 50001,
          ranking: 'SSS',
        },
        {
          _id: '12223',
          user_id: '1',
          user_name: 'CanAll',
          address_wallet: '0xDE108F603A85F24BaaF75Bb3b870a131b10b590A95',
          crt_dt: '1726628488',
          rank: 'Beginner',
          bit: 5061,
          ma_point: 50001,
          ranking: 'SSS',
        },
      ],
    },
    {
      _id: '66ce8e0893924b79a751d3f81231111',
      sectorName: 'Sector 3',
      totalUser: 100,
      createAt: '1726628488',
      user: [
        {
          _id: '123',
          user_id: '11',
          user_name: '62222ad5a6sd4asd4a65d4a65d46a5d4ad4a6d',
          address_wallet: '0xDE108F603A85F24BaaF75Bb3b870a131b10b590A',
          crt_dt: '1726628488',
          rank: 'Beginner',
          bit: 5061,
          ma_point: 50001,
          ranking: 'SSS',
        },
        {
          _id: '12223',
          user_id: '1',
          user_name: 'CanAll',
          address_wallet: '0xDE108F603A85F24BaaF75Bb3b870a131b10b590A95',
          crt_dt: '1726628488',
          rank: 'Beginner',
          bit: 5061,
          ma_point: 50001,
          ranking: 'SSS',
        },
      ],
    },
  ],
}
