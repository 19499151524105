import PropTypes from 'prop-types'
import { useTranslation } from '~/common/hooks/useTranslation'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Pagination,
  Row,
  Select,
  Tooltip,
} from 'antd'
import React, { useRef, useState, useEffect } from 'react'
import { formatAddress, formatNumber } from '~/helpers/common'
import configs from '~/configs'
import { fakeDataSector } from '../fakedata'
import moment from 'moment'
import { DATE_TIMEFORMAT_YYYY_MM_dd_HH_mm2 } from '~/common/constants'
import { useQueryState } from '~/common/hooks/useQueryState'

function UserRow({
  item,
  index,
  totalItems,
  handChange,
  dataSector,
  dataUserSector,
}) {
  const nameRef = useRef(null)
  const [isTruncated, setIsTruncated] = useState(false)

  const filterDataSectorDefault = dataSector?.filter(
    (item) => item?._id === dataUserSector?._id,
  )

  useEffect(() => {
    if (nameRef.current) {
      setIsTruncated(nameRef.current.scrollWidth > nameRef.current.clientWidth)
    }
  }, [item?.user_name])

  return (
    <Row
      key={index}
      style={{
        background: '#0D2758',
        padding: '16px',
        borderBottom:
          index !== totalItems - 1
            ? '1px solid rgb(36, 169, 250, 0.3)'
            : 'none',
        borderRadius: index === totalItems - 1 ? '0 0 8px 8px' : '0',
      }}
    >
      <Tooltip title={isTruncated ? item?.user_name : ''}>
        <Col
          span={3}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
            alignContent: 'center',
          }}
          className={isTruncated ? '' : 'flex items-center justify-center'}
          ref={nameRef}
        >
          {item?.user_name}
        </Col>
      </Tooltip>

      <Col span={3} className="flex items-center justify-center">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${configs.NETWORK.scan}address/${item?.address_wallet}`}
          style={{ color: '#40A9FF' }}
        >
          {formatAddress(item?.address_wallet)}
        </a>
      </Col>
      <Col span={3} className="flex items-center justify-center">
        {item?.rank}
      </Col>
      <Col span={3} className="flex items-center justify-center">
        {item?.ranking ? item?.ranking : 'ー'}
      </Col>
      <Col span={2} className="flex items-center justify-center">
        {item?.bit ? formatNumber(item?.bit) : 'ー'}
      </Col>
      <Col span={3} className="flex items-center justify-center">
        {item?.ma_point ? formatNumber(item?.ma_point) : 'ー'}
      </Col>
      <Col span={2} className="flex items-center justify-center">
        {moment(item?.crt_dt * 1000)
          .utc()
          .format(DATE_TIMEFORMAT_YYYY_MM_dd_HH_mm2)}
      </Col>
      <Col
        span={5}
        className="flex items-center justify-center text-[#40A9FF] text-[20px] cursor-pointer"
      >
        <Select
          defaultValue={filterDataSectorDefault[0]?.sectorName}
          className="w-[90%]"
          onChange={handChange}
          options={dataSector?.map((data, i) => {
            const monster = {
              key: i?.toString(),
              value: data?._id,
              label: data?.sectorName,
            }
            return monster
          })}
        />
      </Col>
    </Row>
  )
}

function SectorDetail(props) {
  const { t } = useTranslation()
  const { id, isShowModal, onClose, refreshData, dataSector } = props

  const { page, pageSize, setPage, setPageSize, keyword, setKeyword } =
    useQueryState()
  const [checkChangeSector, setCheckChangeSector] = useState(true)
  const handleClose = () => {
    onClose()
  }
  const [formSearch] = Form.useForm()
  const handChange = (values) => {
    setCheckChangeSector(false)
  }
  const handleSearch = (values) => {
    setKeyword(values?.keyword)
  }
  return (
    <Modal
      title={`Sector Detail (${dataSector?.sectorName})`}
      onCancel={handleClose}
      open={isShowModal}
      width={1300}
      centered
      destroyOnClose
      footer={null}
    >
      <div className="flex gap-0 sm:gap-3 flex-wrap mb-3 sm:mb-0 w-full sm:justify-end">
        <Form
          onFinish={handleSearch}
          className="w-full sm:w-auto"
          form={formSearch}
          translate="yes"
          autoComplete="off"
          size="middle"
          validateTrigger={['onBlur', 'onChange']}
          requiredMark={false}
        >
          <Form.Item
            className="w-full"
            label={
              <span className="font__M__plus font-bold">
                {t('common.search')}
              </span>
            }
            name="keyword"
            labelAlign="left"
          >
            <Input className="w-full" placeholder={t('common.search')} />
          </Form.Item>
        </Form>
      </div>
      <div className="ContainerGiftDetail">
        <Row
          style={{
            fontFamily: 'Rexliafree',
            padding: '16px',
          }}
        >
          <Col
            span={3}
            style={{ fontSize: '16px' }}
            className="flex items-center justify-center"
          >
            {t('managementUsers.form.name')}
          </Col>
          <Col
            span={3}
            style={{ fontSize: '16px' }}
            className="flex items-center justify-center"
          >
            {t('managementUsers.form.walletAddress')}
          </Col>
          <Col
            span={3}
            style={{ fontSize: '16px' }}
            className="flex items-center justify-center"
          >
            {t('managementUsers.form.rank')}
          </Col>
          <Col
            span={3}
            style={{ fontSize: '16px' }}
            className="flex items-center justify-center"
          >
            {t('managementUsers.form.ranking')}
          </Col>
          <Col
            span={2}
            style={{ fontSize: '16px' }}
            className="flex items-center justify-center"
          >
            {t('managementUsers.form.bit')}
          </Col>
          <Col
            span={3}
            style={{ fontSize: '16px' }}
            className="flex items-center justify-center"
          >
            {t('managementUsers.form.maPoint')}
          </Col>
          <Col
            span={2}
            style={{ fontSize: '16px' }}
            className="flex items-center justify-center"
          >
            {t('managementUsers.form.createdAt')}
          </Col>
          <Col
            span={5}
            style={{ fontSize: '16px' }}
            className="flex items-center justify-center"
          >
            {t('sector.changeSector')}
          </Col>
        </Row>

        {dataSector?.user?.map((item, index) => (
          <UserRow
            key={index}
            item={item}
            index={index}
            totalItems={dataSector.user.length}
            handChange={handChange}
            dataSector={fakeDataSector.data}
            dataUserSector={dataSector}
          />
        ))}
      </div>
      <div className="pagination__common scrollbar_container mb-4 mt-[-50px] ">
        <Pagination
          className="pageSector"
          current={page}
          pageSize={pageSize}
          total={dataSector?.user?.length}
          onChange={(newPage, newPageSize) => {
            setPage(newPage)
            setPageSize(newPageSize)
          }}
          showSizeChanger
        />
      </div>
      <div key="buttons" className="flex justify-end mr-[6%]">
        <Button
          // className="border-hidden"
          key="ok"
          htmlType="submit"
          type="primary"
          // style={okButtonStyle}
          disabled={checkChangeSector}
          // loading={isLoadingCreatePool}
        >
          {t('common.btn.change')}
        </Button>
      </div>
    </Modal>
  )
}

SectorDetail.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default SectorDetail
